import React, { Component } from "react";
import Slider from "react-slick";
import 'bootstrap/dist/css/bootstrap.css';
import LazyImage from './../../components/lazyImage';
import buySideRep from './../../assets/images/resources/book-cover-1.png';
import buySideRepPoster from './../../assets/images/resources/book-cover-1.png';
import '../../assets/styles/css/pages/resources/resources.css';

export default class PlayBookSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    //   autoplaySpeed: 2000,
      arrows: false,

    };
    return (
      <div className="play-book">
        <Slider {...settings}>
            <div>
                <div className="row">
                    <div className="col-lg-4">
                        <LazyImage
                            className="service-image img-fluid"
                            src={buySideRep}
                            placeholderImg={buySideRepPoster}
                            alt="buy-side-rep"
                        />
                    </div>
                    <div className=" col-lg-8">
                        <h3>Acquisition &amp; Due Diligence Playbook</h3>

                        <p className="mb-3 text-dark">The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors
                        <br/><br/> The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors</p>

                         <a className="float-right more-text" href="/resources#contact_us_form"><i className="fas fa-plus mr-1"></i>More</a>
                    </div>
                </div>
            </div>
            {/* <div>
                <div className="row">
                    <div className="col-lg-4">
                        <LazyImage
                            className="service-image img-fluid"
                            src={buySideRep}
                            placeholderImg={buySideRepPoster}
                            alt="buy-side-rep"
                        />
                    </div>
                    <div className=" col-lg-8">
                    <h3>Acquisition &amp; Due Diligence Playbook</h3>

                        <p className="mb-3 text-dark">The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors
                        <br/><br/> The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors</p>

                            <a className="float-right more-text" href="/resources#contact_us_form"><i className="fas fa-plus mr-1"></i>More</a>
                    </div>
                </div>
            </div> */}
            {/* <div>
                <div className="row">
                    <div className="col-lg-4">
                        <LazyImage
                            className="service-image img-fluid"
                            src={buySideRep}
                            placeholderImg={buySideRepPoster}
                            alt="buy-side-rep"
                        />
                    </div>
                    <div className=" col-lg-8">
                    <h3>Acquisition &amp; Due Diligence Playbook</h3>

                        <p className="mb-3 text-dark">The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors
                        <br/><br/> The content on our website and within the Axis M&amp;A platform, including this sample playbook, is provided for general information purposes only. The provision of this sample playbook does not constitute legal advice or opinions of any kind. Your use of this document is at your own risk. You should not use this document without first seeking legal and other professional advice. No lawyer client, advisory, fiduciary or other relationship exists between Axis M&amp;A and any person accessingor otherwise using this document. Axis M&amp;A and its affiliates (and any of their respective directors</p>

                         <a className="float-right more-text" href="/resources#contact_us_form"><i className="fas fa-plus mr-1"></i>More</a>
                    </div>
                </div>
            </div> */}
          
        </Slider>
      </div>
    );
  }
}