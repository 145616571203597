import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import logoLight from '../../assets/images/logo1.png';
import '../../assets/styles/css/pages/home/footer.css';
import '../../assets/plugins/fontawesome/css/all.min.css';

const Footer = () => (
    <div className="footer-container">
        <div className="container">
            <div className="footer-link-logo-container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="footer-link-container text-center">
                            <div className="footer-link-list">
                                <a href="/services#buySide" className="footer-link-text">
                                    Buy a Business
                                </a>
                            </div>
                            <div className="footer-link-list">
                                <a href="/services#sellSide" className="footer-link-text">
                                    Sell a Business
                                </a>
                            </div>
                            <div className="footer-link-list">
                                <a href="/about-us" className="footer-link-text">
                                    About Us
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="footer-address">
                            <div className="contact-us">Contact Us: </div>
                            <ul>
                                <li>555 NORTH POINT CENTER EAST</li>
                                <li>4TH FLOOR</li>
                                <li>ALPHARETTA</li>
                                <li>Georgia-30022</li>
                                <li>Phone #: (678) 366-4300</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 order-lg-last order-first">
                        <div className="footer-logo-container">
                            <Link to="/">
                                <img
                                    src={logoLight}
                                    alt="logo-light"
                                    className="logo-new img-fluid"
                                    onClick={() => <Redirect to="/" />}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-copyrights-followus-container">
            <div className="row no-gutters">
                <div className="col-md-6">
                    <div className="footer-follow-us">
                        &#169; AxisM&A 2021. All rights reserved.
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="footer-followus-container">
                        <div className="links">
                            <div id="fb-dark">
                                <i className="fab fa-facebook-f"></i>
                            </div>
                            <div id="google-dark">
                                <i className="fab fa-youtube"></i>
                            </div>
                            <div id="twitter-light">
                                <i className="fab fa-twitter"></i>
                            </div>
                            <div
                                id="linkedin-dark"
                                onClick={() =>
                                    window.open(
                                        'https://www.linkedin.com/company/axis-m-a/',
                                        '_blank'
                                    )
                                }
                            >
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;
