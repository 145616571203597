import React, { Component } from "react";
import Slider from "react-slick";
import 'bootstrap/dist/css/bootstrap.css';
import LazyImage from '../components/lazyImage';
import buySideRep from './../assets/images/slide-home-1.jpg';
import buySideRep1 from './../assets/images/home/slide-home-2.jpg';
import buySideRep2 from './../assets/images/home/slide-home-3.jpg';
 import buySideRepPoster from './../assets/images/slide-home-1.jpg';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,

    };
    return (
      <div>
        <Slider {...settings}>
     
          <div>
          <div className="image-wrapper">
          <LazyImage
                className="service-image home-service-img img-fluid"
                src={buySideRep1}
                placeholderImg={buySideRepPoster}
                alt="mergers and acquisitions"
                title="mergers and acquisitions"
            />
            </div>
            <div className="image-text-wrapper">
                <div className="container">
                    <div className="video-left-text">
                        <h1><span className="text-orange">Axis M&A <br /><b>Guarantees </b></span>the sale of your<b> Business</b></h1>
                       
                        <p className="mb-3">Axis M&A is the only firm in the industry that <b>guarantees</b> the sale of your business. We are able to offer this guarantee because we have access to buyers with verified funds; additionally, Axis M&A has been retained by companies in the US, and internationally, that are actively searching for potential acquisitions to execute their inorganic growth strategies.</p>
                      
                      <a className="h4 d-inline-block mb-5 text-orange" href="/#bookAppointment">Contact us to learn more</a>
                    </div>
                </div>
            </div>
          </div>
          <div>
          <div className="image-wrapper">
          <LazyImage
              className="service-image home-service-img img-fluid"
              src={buySideRep}
              placeholderImg={buySideRepPoster}
              alt="mergers and acquisitions"
                title="mergers and acquisitions"
          />
          </div>
          <div className="image-text-wrapper">
                <div className="container">
                    <div className="video-left-text">
                        <h1><span className="text-orange">Exit with <br /><b>Confidence </b></span> into the<b> Future</b></h1>
                        <p>Axis M&A was created to help businesses identify opportunities, and navigate their way through M&A transactions with unmatched efficiency</p>
                       
                    </div>
                </div>
            </div>
          </div>
           <div>
           <div className="image-wrapper">
          <LazyImage
                className="service-image home-service-img img-fluid"
                src={buySideRep2}
                placeholderImg={buySideRepPoster}
                alt="mergers and acquisitions"
                title="mergers and acquisitions"
            />
            </div>
            <div className="image-text-wrapper">
                <div className="container">
                    <div className="video-left-text">
                        <h1><span className="text-orange">Axis M&A <br /><b>2021 </b></span><b> Playbook</b></h1>
                        <p className="mb-3"><a className="text-white" href="/resources">Get access to Axis M&A Acquisition and Due Diligence Playbook.</a></p>
                     
                    </div>
                </div>
            </div>
          </div>
          
        </Slider>
      </div>
    );
  }
}