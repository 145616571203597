import React from 'react';

import '../assets/styles/css/components/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
              <div className="row no-gutters">
            <div className="col-md-6">
            <div className="footer-follow-us">&#169; AxisM&A 2021. All rights reserved.</div>
            </div>
            <div className="col-md-6">
            <div className="footer-followus-container">
                <div className="links">
            <div  id="fb-dark"><i className="fab fa-facebook-f"></i></div>
                <div  id="google-dark" ><i className="fab fa-youtube"></i></div>
                <div  id="twitter-light"><i className="fab fa-twitter"></i></div>
                {/* <div  id="twitter-light"><i className="fab fa-twitter"></i></div> */}
                <div
                    id="linkedin-dark"
                    onClick={() =>
                        window.open('https://www.linkedin.com/company/axis-m-a/', '_blank')
                    }
                ><i className="fab fa-linkedin-in"></i></div>                
                </div>
                </div>
                </div>
                </div>
        </footer>
    );
};

export default Footer;
